import { vlogger } from 'shared/service/logger/vlogger';
import { VelocityError } from 'shared/model/velocity-error';

/* istanbul ignore file */
export class VelocityErrorHelper {
  /**
   * Stringify the VelocityError messages
   * @param errors Array of VelocityError
   * @param operationId Operation or Correlation ID for tracking request
   */
  static Stringify(errors: VelocityError[], operationId = '') {
    let str = '';

    for (let i = 0; i < errors.length; i++) {
      // str.concat(`Error: ${operationId ? 'opID(' + operationId + '), ' : ''} ${errors[i].message}\n`);
      str += `Error: ${operationId ? 'opID(' + operationId + '), ' : ''} ${errors[i].message}\n`;
    }

    return str;
  }

  /**
   * Send errors to logger
   * @param errors Array of VelocityError
   * @param operationId Operation or Correlation ID for tracking request
   */
  static Logify(errors: VelocityError[], operationId = '', trace = false, http = false) {
    vlogger.error(this.Stringify(errors, operationId), trace, http);
  }
}
